// Responsive breakpoints
var smBreakpoint = 768;
var mdBreakpoint = 992;
var lgBreakPoint = 1200;

window.positionSwipperBullets = function() {}

$(document).ready(function() {

    changeGallerySwiper();
    initGallerySwiperEvents();
    changeSwiperParams();

    $('#navbar-collapse').on('show.bs.modal', function() {

        $('.navbar-toggle').addClass('modal-open');

    });

    $('#navbar-collapse').on('hide.bs.modal', function() {

        $('.navbar-toggle').removeClass('modal-open');

    });

    $(document.body).on('click touchend', '#swipebox-slider .current img', function(e) {
        return false;
    }).on('click touchend', '#swipebox-slider .current', function(e) {
        $('#swipebox-close').trigger('click');
    });


    // only show teasermodal if cookie popup has been closed again to prevent inference
    var initTeaserModalTimeout = 0;
    function initTeaserModalOnlyAfterCookiePopup() {
        var cookiepopup = document.querySelector('div.pkcp').cookiepopup;

        if (!cookiepopup && ++initTeaserModalTimeout < 5) {
            setTimeout(initTeaserModalOnlyAfterCookiePopup, 100);
        } else if (!cookiepopup) {
            teasermodal();
        } else if (cookiepopup.status.isAnyDismissed()) {
            window.addEventListener('sensitive_data_loaded', teasermodal, {once: true});
        } else {
            teasermodal();
        }
    }

    initTeaserModalOnlyAfterCookiePopup();
});

window.teasermodal = function() {
    var shown = sessionStorage.getItem('bergerhausen-teaser-modal');

    if (shown !== 'shown' && $('#teaser-modal').length > 0 && $('#teaser-modal .modal-body').html().trim().length > 0) {
        if (!$('#neos-application').length) {
            setTimeout(function() {
                $('#teaser-modal').modal('show');
            }, 500);
        }

        $('#teaser-modal').on('show.bs.modal', function(e) {
            $('body').css('overflow', 'hidden');
        });
        $('#teaser-modal').on('hidden.bs.modal', function(e) {
            $('body').css('overflow', '');
        });

        var cookiepopup = document.querySelector('div.pkcp').cookiepopup;
        if (cookiepopup.status.hasConsented('functional')) {
            sessionStorage.setItem('bergerhausen-teaser-modal', 'shown');
        }
    }
}

window.changeSwiperParams = function() {
    if ($('.swiper-container').length) {
        $('.swiper-container').not('.gallery').each(function() {
            this.swiper.params.autoplay = 2000;
            this.swiper.update();
        });
    }
}


window.initGallerySwiperEvents = function() {

    $('.gallery-item .content').on('click', function() {

        var overlay = $(this).parent().parent().find('.overlay');
        overlay.css('left', $(this).closest('.swiper-wrapper').css('transform').split(',')[4] * -1);
        overlay.addClass('open');

        $('.gallery .prev-slide').add($('.gallery .next-slide')).addClass('overlay-open');
        $(this).closest('.gallery').get(0).swiper.lockSwipes();

    });

    $('.gallery-item .overlay .close').on('click', function() {

        $(this).closest('.overlay').removeClass('open');
        $('.gallery .prev-slide').add($('.gallery .next-slide')).removeClass('overlay-open');
        $(this).closest('.gallery').get(0).swiper.unlockSwipes();

    });

}

window.changeGallerySwiper = function() {

    if ($('.swiper-container.gallery').length) {

        $('.swiper-container.gallery').each(function() {

            var curSwiper = this.swiper;
            var wrapperId = $(this).parent().attr('id');

            if ($(window).width() > 768) {

                curSwiper.params.slidesPerView = 2;

            }

            curSwiper.params.autoplay = false;
            curSwiper.update();
            curSwiper.stopAutoplay();

            if (curSwiper.isEnd) {

                $('div[id=' + wrapperId + '] .next-slide i').css('opacity', '0.3');

            } else {

                $('div[id=' + wrapperId + '] .next-slide i').css('opacity', '1');

            }

            curSwiper.on('slideChangeStart', function() {

                if (curSwiper.isBeginning) {

                    $('div[id=' + wrapperId + '] .prev-slide i').css('opacity', '0.3');

                } else {

                    $('div[id=' + wrapperId + '] .prev-slide i').css('opacity', '1');

                }

                if (curSwiper.isEnd) {

                    $('div[id=' + wrapperId + '] .next-slide i').css('opacity', '0.3');

                } else {

                    $('div[id=' + wrapperId + '] .next-slide i').css('opacity', '1');

                }

            });

        });

    }

}